/* Home.css */
@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,300;0,400;0,500;0,700;1,400&display=swap');

html, body {
  height: 100%;
  margin: 0;
  font-family: 'Kanit', sans-serif;
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('../img/bg_allpimg.png');
  color: white;
  background-size: cover;
  background-position: center;
  text-align: center;
  font-family: Kanit, sans-serif;
}

.header {
  background-color: #4B0082;
  margin-top: -5px;
}

.header img {
  height: 60px;
}

.main {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  background-size: cover;
  margin-top: 5%;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-text {
  color: #f60;
  font-size: 4.5rem;
  margin-top: -100px;
  margin-bottom: -200px;
}

.sub-text {
  flex-direction: row;
    height: auto;
    justify-content: space-around;
    margin-top: -120px;
    width: 50%;
    color: #fff;
    font-weight: 700;
    font-size: 2.8rem;
}

.start-button {
  margin-top: 30%;
  background-color: #f60;
    border-radius: 10px;
    color: #fff;
    flex-direction: column;
    font-size: 4rem;
    font-weight: 700;
    height: 110px;
    justify-content: center;
    text-align: center;
    width: 450px;
}

.start-button:hover {
  background-color: #ff4500;
}
