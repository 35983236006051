@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
    margin: 0;
    color: #4B0082;
    text-align: center;
    padding-top: 5px; /* Ajuste a altura conforme necessário */
  }

  .home-page {
    text-align: center;
  }
  
  .header-plan {
    align-items: center;
    background-color: #4B0082;
    height: 60px;   
  }
  .header-container-plan{
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: #4B0082;
    height: 60px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    
  }
  
  .back-button {
    background-color: #ff6600;; 
    color: white; 
    border: none; 
    border-radius: 5px; 
    padding: 10px 20px; 
    font-size: 16px; 
    font-family: 'Kanit';
    font-weight: 800;
    cursor: pointer; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
    text-transform: uppercase; 
  }

  .back-button > span {
    font-weight: 300;
    cursor: pointer;
  }
  

  .iframe-container{
    text-align: center; 
    margin-top: -165px;
  }

  .plains-page{
    width: 80%;
    height: 100%;
  }

  .iframe-main{
    width: 85%;
    height: 100%;
    border: none; /* ou 0 */
    overflow-y: hidden; 
  }

