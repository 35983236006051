@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  margin: 0;
  font-family: Arial, sans-serif;
  color: white;
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('../img/bg_allpimg.png'); 
  text-align: center;
}

.home-page {
  text-align: center;
}

.header {
  background-color: #470E75; 
  padding: 10px 0;
}

.header img {
  height: 50px;
}

.main {
  position: relative;
  color: white;
  width: 100%;
  height: 100vh;
}

.background {
  width: 100%;
  height: auto;
}

.main-text {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 4.5rem;
  font-family: "Kanit", sans-serif;
  font-weight: 700;
  font-style: normal;
  color: #ff6600;
}

.sub-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2.5rem;
  font-weight: bold;
  font-family: "Kanit", sans-serif;
  font-weight: 500;
  font-style: normal;
  color: white;
}

.icons {
  position: absolute;
  bottom: 15%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  gap: 10px;
  justify-content: space-around;
  width: 40%;
}

.icon {
  text-align: center;
}

.icon img {
  height: 70px;
}

.icon p {
  margin-top: 10px;
  font-family: "Kanit", sans-serif;
  font-weight: 500;
  font-style: normal;
}
@media screen and (min-width: 1920px) and (min-height: 1080px) {
  .main-text {
    font-size: 6rem; 
  }

  .sub-text {
    font-size: 3.5rem; 
  }

  .icons {
    width: 30%; 
    top: 70%;
    left: 18%;
    gap: 100px;
  }

  .icon img {
    height: 100px; 
  }

  .icon p {
    font-size: 2rem; 
    white-space: nowrap;
  }
}